import React, { memo } from "react"
import Layout from "../components/Layout"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import HeadingWithDescription from "../components/HeadingWithDescription"
function UserDeletionPolicy({ data }) {
  const backgroundImage = data.DetailsBg.childImageSharp.fluid.src
  return (
    <Layout
      className="details"
      pageTitle={"Visiomate - User Deletion Policy"}
      descrip={"User Deletion Policy"}
    >
      <div
        className="details-Conatiner"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="details-div-for-logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="detailsHeader">
          <h1>
            User Deletion Policy
          </h1>
        </div>
      </div>
      <div className="wrapper-div">

        <HeadingWithDescription
          headingText="User Cancellation Policy - Visioedge"
        >
            <ol>
                <li className="text-dflt-color text-bottom-margin">Introduction Visioedge is committed to ensuring the utmost satisfaction of its users.
However, we understand that there may be circumstances in which users wish to cancel
their account. This policy describes the procedure for requesting the cancellation of the
account associated with the Visioedge app.</li>
                <li className="text-dflt-color text-bottom-margin">Cancellation Procedure Users who wish to cancel their account may do so by calling the
HR department. One of our representative will
assist you in the cancellation procedure.</li>
                <li className="text-dflt-color text-bottom-margin">Cancellation Procedure
                    <span className="text-dflt">User Identification: When calling, you will be asked to provide identifying information to
confirm your identity. This may include your full name, the email address associated with
your account, and other information necessary to ensure security.
</span>
<span className="text-dflt">Confirmation of Request: Once your identity has been verified, the operator will proceed to
confirm your cancellation request. You will be asked to verbally confirm that you wish to
cancel your account.
</span>
<span className="text-dflt">Account Cancellation: After confirmation, your account will be cancelled within 72 hours
of your request. A notification confirming your cancellation will be sent to the email
address associated with your account.</span>
                </li>
                <li className="text-dflt-color text-bottom-margin">Effects of Cancellation
                    <span className="text-dflt">Access to Data: Once your account has been cancelled, you will no longer have access to
the Visioedge app or any data associated with it. Please note that account
cancellation is irreversible.</span>
<span className="text-dflt">Data Retained: Visioedge reserves the right to retain certain data for a limited period of
time if necessary for legal or administrative reasons, in accordance with applicable laws.</span>
                </li>
                <li className="text-dflt-color text-bottom-margin">Contact Us For any questions or assistance regarding account cancellation, users
may contact our customer service at +92 321 4050 200.
</li>
                <li className="text-dflt-color text-bottom-margin">Policy Changes Visioedge reserves the right to change this policy at any time.
Changes will be communicated to users through the app and will be effective
immediately.
</li>
                <li className="text-dflt-color text-bottom-margin">Effective Date This policy is effective as of January 02, 2025.</li>
                <li className="text-dflt-color text-bottom-margin">Privacy User privacy is a priority for Visioedge. Account deletion will be handled
                in accordance with current data protection regulations</li>
            </ol>
        </HeadingWithDescription>
      </div>
    </Layout>
  )
}

export default memo(UserDeletionPolicy)
export const query = graphql`
  query userDeletionPolicy {
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    DetailsBg: file(relativePath: { eq: "Maskgroup8copy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
