import React, { useEffect, useState } from "react"
import { Layout as AntLayout } from "antd"
import {
  RecoilRoot,
} from 'recoil';
import Header from "./Header"
import Footer from "./Footer"
import TalkToUs from '../components/Talktous'
import "antd/dist/antd.css"
import "../styles/layout.scss"
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
const Layout = ({ children, className, data, style,pageTitle,descrip }) => {
  const { Content } = AntLayout
  const location = useLocation();
  const [cookieModal,setCookieModal]=useState(true)
  const [user,setUser]=useState(null)

  const acceptCookie=()=>{
    setCookieModal(false)
    localStorage.setItem('user',true)
  }
  const declineCookie=()=>{
    setCookieModal(false)
    JSON.stringify(localStorage.setItem('user',false))
  }
  useEffect(()=>{
    if(window!==undefined){
      setUser(localStorage.getItem('user'))
    }
  },[])
  return (
    <RecoilRoot>
      <AntLayout >
      <title>{pageTitle}</title>
      <Helmet>
      <meta name="description" content={descrip} />
      <link rel="canonical" href={`${location.href}`} key="canonical-link" />
      </Helmet>
        <Header data={data} />
        {(cookieModal&&user===null)&&
        <div className="cookie_div">
          <p className='cookie_text'>This site uses cookies and related technologies, as described in our privacy policy, for purposes that may include site operation, analytics, enhanced user experience, or advertising. You may choose to consent to our use of these technologies, or manage your own preferences.</p>
          <div className='cookie_buttons'>
            <button className='cookie_button accept_btn' onClick={acceptCookie}>Accept</button>
            <button className='cookie_button decline_btn' onClick={declineCookie}>Decline</button>
          </div>
        </div>}
        <Content className={className} style={style} >
          {children}
        </Content>
        {!(location.pathname.includes('privacypolicy') || location.pathname.includes('userdeletionpolicy')) && <TalkToUs />}
        <Footer data={data} />
      </AntLayout>
    </RecoilRoot>
  )
}

export default Layout
