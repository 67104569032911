import React, { memo } from "react"
import Layout from "../components/Layout"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import HeadingWithDescription from "../components/HeadingWithDescription"
function Privacypolicy({ data }) {
  const backgroundImage = data.DetailsBg.childImageSharp.fluid.src
  return (
    <Layout
      className="details"
      pageTitle={"Visiomate - Privacy Policy"}
      descrip={"Privacy Policy"}
    >
      <div
        className="details-Conatiner"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="details-div-for-logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="detailsHeader">
          <h1>
            Privacy Policy
          </h1>
        </div>
      </div>
      <div className="wrapper-div">
        <HeadingWithDescription
          headingText="Introduction"
          descriptionText="This information is provided pursuant to Regulation (EU) 2016/679 of the European
                Parliament and of the Council of 27 April 2016 on the protection of natural persons with
                regard to the processing of personal data and on the free movement of such data (General
                Data Protection Regulation – GDPR)."
        />
        <HeadingWithDescription
          headingText="Data Controller"
          descriptionText="The Data Controller is Visioedge, with registered office in 1st Floor, 126 Lahore – Kasur Rd, Saroba Garden Housing Scoiety, Lahore, Punjab 54700, Pakistan."
        />
        <HeadingWithDescription
          headingText="Personal data processed"
          descriptionText={
            <>
            <span className="text-dflt">The Personal Data processed by the Data Controller includes, by way of example:</span>
            <span className="text-dflt">Personal data (name, surname, date of birth, gender)</span>
            <span className="text-dflt">Contact details (telephone number, email address)</span>
            <span className="text-dflt">Data relating to the geographical location of the registered office of your Company/the
            Company you represent</span>
            <span className="text-dflt">Corporate name of your Company/the Company you represent</span>
            </>
            }
        />
        <HeadingWithDescription
          headingText="Purpose of processing"
          descriptionText={
            <><span className="text-dflt">Personal data is processed for the following purposes:</span>
<span className="text-dflt">Negotiation, signing and execution of the commercial agreement</span>
<span className="text-dflt">Fulfillment of regulatory obligations</span>
<span className="text-dflt">Sending of information and promotional material (subject to consent)</span>
</>}
        />
        <HeadingWithDescription
          headingText="Legal basis for processing"
          descriptionText="The legal basis for processing is the legitimate interest of the Data Controller to carry out
its commercial activity, as well as the fulfillment of regulatory obligations. For sending
information and promotional material, the legal basis is the consent of the interested
party.
            "
        />
        <HeadingWithDescription
          headingText="Processing methods"
          descriptionText="Personal data are processed using paper, computer and telematic tools, with logic strictly
related to the purposes above and in compliance with the security measures provided for
by the GDPR."
        />
        <HeadingWithDescription
          headingText="Scope of circulation of Data"
          descriptionText={<>
          <span className="text-dflt">Personal Data may be communicated to:</span>
<span className="text-dflt">Third parties who carry out activities on behalf of the Data Controller (for example: credit
institutions, professional firms, suppliers/consultants)</span>
<span className="text-dflt">Supervisory bodies, judicial authorities</span>
<span className="text-dflt">Other parties to whom communication is mandatory by law</span>
<span className="text-dflt">Personal Data will not be disclosed to unspecified parties.</span></>}
        />
        <HeadingWithDescription
          headingText="Transfer of Data Abroad"
          descriptionText="Personal Data will not be transferred."
        />
        <HeadingWithDescription
          headingText="Retention Period"
          descriptionText="Personal Data will be retained for the time strictly necessary to achieve the purposes set
out above, in compliance with the principles of minimization and proportionality."
        />
        <HeadingWithDescription
          headingText="Rights of the Data Subject"
          descriptionText="The Data Subject has the right to:"
        >
            <ol>
                <li className="text-dflt-color">Access their Personal Data</li>
                <li className="text-dflt-color">Obtain the rectification or erasure of their Personal Data</li>
                <li className="text-dflt-color">Limit the processing of their Personal Data</li>
                <li className="text-dflt-color">Oppose the processing of their Personal Data</li>
                <li className="text-dflt-color">Transfer their Personal Data to another Data Controller (portability)</li>
                <li className="text-dflt-color">Propose a complaint to the Data Protection Authority</li>
            </ol>
        </HeadingWithDescription>
        <HeadingWithDescription
          headingText="How to exercise your rights"
          descriptionText="The Data Subject may exercise their rights by sending a request to the Data Controller at
the following email address: info@visiomate.com."
        />
        <HeadingWithDescription
          headingText="Updates"
          descriptionText="This information was updated on January 02, 2025."
        />
        <HeadingWithDescription
          headingText="Notes"
          descriptionText={
            <>
            <span className="text-dflt">This information is provided in summary form. For a complete version, please contact the
Data Controller.</span>
<span className="text-dflt">The Data Controller reserves the right to modify this information at any time, due to any
regulatory or practice changes.</span></>}
        />
        <HeadingWithDescription
          headingText="INFORMATION ON THE USE OF COOKIES"
          descriptionText="This information is provided pursuant to Regulation (EU) 2016/679 of the European
Parliament and of the Council of 27 April 2016, on the protection of natural persons with
regard to the processing of personal data and on the free movement of such data (General
Data Protection Regulation – GDPR)."
        />
        <HeadingWithDescription
          headingText="What are Cookies?"
          descriptionText="Cookies are text files that websites visited by the user send to his terminal (usually to the
browser), where they are stored before being re-transmitted to the same sites on the next
visit by the same user. Their operation is entirely dependent on the navigation browser that
the user uses and can be enabled or disabled by the user."
        />
        <HeadingWithDescription
          headingText="How can I control the installation of Cookies?"
          descriptionText="The user can manage preferences relating to Cookies directly within their browser and
prevent – for example – third parties from installing them. Through the browser preferences
it is also possible to delete Cookies installed in the past, including the Cookie in which the
consent to the installation of Cookies by this site is possibly saved."
        />
        <HeadingWithDescription
          headingText="Types of Cookies used"
          descriptionText={
            <><span className="text-dflt">Technical and aggregate statistical cookies</span>
These Cookies are necessary for the correct functioning of the website and for the
collection of statistical data in aggregate form</>}
        />
        <HeadingWithDescription
          headingText="Profiling cookies (third parties)"
          descriptionText={
            <><span className="text-dflt">These Cookies are used to send advertising in line with the user's preferences</span>
<span className="text-dflt">For more information on each service and to disable the related Cookies, please consult
the privacy policies of the respective services.</span>
<span className="text-dflt">Last revision: January 02, 2025</span>
<span className="text-dflt">For more information on the processing of your personal data:</span>
<span className="text-dflt">Privacy Policy: <Link to="/privacypolicy"><strong>https://www.visiomate.com/privacypolicy</strong></Link> Contacts: <Link to="/contact"><strong>https://www.visiomate.com/contact</strong></Link></span>
**This Cookies Policy, compliant with the GDPR, together with the other documents
pursuant to EU Regulation 2016/679, constitutes the Privacy Policy of this site
</>}
        />
      </div>
    </Layout>
  )
}

export default memo(Privacypolicy)
export const query = graphql`
  query privacyDetails {
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    DetailsBg: file(relativePath: { eq: "Maskgroup8copy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
