import React from 'react'
import Subheading from './Subheading'

function HeadingWithDescription({headingText,descriptionText,children}) {
  return (
    <>
        <Subheading>{headingText}</Subheading>
        <p>{descriptionText}</p>
        {children}
    </>
  )
}

export default HeadingWithDescription